import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import HomeImage from '../components/Images/HomeImage';
import logoWhite from '../images/logo-white.svg';


const IndexPage = () => (
  <Layout>
    <SEO title="Accueil" />
    <div className="banner">
      <img src={logoWhite} alt="logo" className="banner-logo" />
      <p><i>Notre lien d’amour, d’amitié,<br />une nouvelle forme d’alliance.</i></p>
      <Link to="#content-scroll" className="down">
        <FontAwesomeIcon icon={faChevronDown} size="lg" />
      </Link>
    </div>

    <div className="home-2-images" id="content-scroll">
      <div>
        <HomeImage image="braceletProfil" />
      </div>
      <div>
        <HomeImage image="ambiance" />
      </div>
    </div>

    <div className="content">
      <div className="home-paragraph">
        <h2>Les inséparables</h2>
        <p>
          « Toi et moi, partageons le même bijou, il nous est singulier et fait de
          nous <i>des inséparables</i>. » C’est ainsi que la marque puise
          naturellement l’inspiration du nom de sa première ligne de
          bracelets : <i>Les inséparables</i>. <br /><br />
          Le bracelet <i>Les inséparables</i> s’inspire, d’un premier bijou, de la fine
          gourmette de naissance portée par les tous jeunes enfants.
          Une plaque, une chaîne, une identité… celle qu’on a depuis toujours,
          celle qu’on a créée au fil des années. La nôtre, unique.
          Lié(e), agrandit la plaque en un demi-jonc plat et l’accompagne d’une
          chaîne qui se double et vient s’accrocher délicatement au bord du jonc.
          L’association du métal précieux, robuste, pur et lisse, aux fines mailles
          souples et légères, exprime le mariage de deux designs, deux entités,
          deux êtres…<br /><br />
          Le modèle se décline dans les trois coloris d’or 18 carats (blanc, jaune et
          rose). Il existe également une version en argent doré et en argent.
        </p>
      </div>
    </div>

    <div style={{ backgroundColor: '#6FA287', color: 'white' }}>
      <div className="content">
        <p className="quote">
          « Toi et moi, partageons le même bijou, il nous est singulier et fait de
          nous <i>des inséparables</i>. »
        </p>
      </div>
    </div>

    <div className="home-2-images">
      <div>
        <HomeImage image="dessin" />
      </div>
      <div>
        <HomeImage image="braceletProfilFerme" />
      </div>
    </div>

    <div className="content">
      <div className="home-paragraph">
        <h2>Le design</h2>
        <p>
            Le design intemporel du bracelet a été pensé pour être
            transgénérationnel.<br /><br />
            Il peut être porté par homme, femme et enfant. Sa chaîne lui permet
            d’être évolutif et de s’agrandir avec l’enfant. Son élégance unisexe
            l’inscrit dans une tendance de bijou non genré, classique et symbolique.
            Il mélange savoir-faire, simplicité et modernité pour que chacun puisse
            facilement l’adopter et le mixer aux tendances de la saison.
        </p>
      </div>
    </div>

    <div style={{ backgroundColor: '#6FA287', color: 'white' }}>
      <div className="content">
        <div className="paragraph-flex home-paragraph">
          <div style={{ flex: 1.5, padding: 0, }}>
            <HomeImage image="chloe" />
          </div>
          <div style={{ flex: 3 }}>
            <h2>La créatrice, Chloé Hirsch</h2>
            <p >
              Après des études théâtrales, Chloé Hirsch a longuement travaillé dans l’univers de la mode comme attachée de presse. Ses passions : la danse, la comédie, la mode et le bijou dans ce qu’il a de plus symbolique. Tout ce que porte Chloé possède une histoire, détient un sens – caché ou non. C’est ce qu’elle recherche avec Lié(e) : l’histoire, l’essentiel, la simplicité. Et donc l’émotion.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="home-2-images">

      <div>
        <HomeImage image="artisanat2" />
      </div>
      <div>
        <HomeImage image="artisanat1" />
      </div>
      <div>
        <HomeImage image="artisanat3" />
      </div>
    </div>

    <div className="content">
      <div className="home-paragraph">
        <div>
          <h2>Un état d’esprit 100% made in France et éthique</h2>
          <p>
            Réalisées dans un atelier joaillier parisien, en plein cœur du quartier
            Drouot, les créations Lié(e) sont entièrement façonnées à la main dans
            la pure tradition joaillière et sont, par conséquent, made in France.
            Chaque étape est pensée dans un souci éthique de la confection du
            bijou à son enveloppe. <br /> <br />
            Le choix d’un écrin naturel fabriqué par une ONG soutenant le travail des
            femmes en Inde, s’est imposé naturellement, afin de respecter à la fois
            l’esthétique de Lié(e) et les problématiques éco-responsables actuelles.
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
